.clock {
    font-size: 24px;
    color: #3f51b5;
    background-color: #12246e;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clock span {
    margin: 10px;
    color: #fff;
    font-weight: bold;
}

.app-announcement {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1100px;
    padding: 20px;
}

.app-column {
    flex: 1;
    text-align: center;
    margin: 20px;
}

.clock-intro {
    margin-top: 50px;
    font-style: italic;
}

@media (max-width: 768px) {
    .app-announcement {
        flex-direction: column;
    }

    .app-column {
        margin: 20px 0;
    }
}

@media (min-width: 600px) {
    .clock {
        flex-direction: row;
    }
}

.app-icon {
    width: 100px;
    /* adjust this value as needed */
}