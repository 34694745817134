html,
body {
  background-color: #f1f1f1;
}

.container {
  text-align: center;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
}